<template>
  <CContainer class="min-vh-100" style="background-color: #ffffff;">
    <CRow>
      <CCard
        color="white"
        text-color="dark"
        class="text-center col-md-5 mt-3"
        body-wrapper>
        <CCard>
          <CAlert v-if="redeemAlert != ''" color="danger" :show="2">
            {{ redeemAlert }}
          </CAlert>
          <div
            class="square-box"
            :style="{
              'background-image': `url('${data.imgUrl}'), url('${noImgUrl}')`,
              width: '100%',
              'background-size': 'cover',
              'background-position': 'center center',
            }"
          ></div>
        </CCard>
        <h4 class="font-weight-normal text-left">
          {{ data.title }}
        </h4>
        <p class="text-left text-description">{{ data.description }}</p>
        <hr />
        <div class="text-left text-grey">
          <em class="fa fa-smile-o"></em>
          คะแนนที่ใช้แลก {{ data.redeemFor || "0" }} คะแนน
        </div>
        <div class="text-left text-grey pt-2 pb-2">
          <em class="fa fa-tag"></em>
          มูลค่าคูปอง {{ data.value || "0" }} บาท
        </div>
        <div class="text-left text-grey">
          <em class="fa fa-calendar-check-o"></em>
          รับสิทธิ์ได้ถึง {{ dateRange(data.startAt, data.endAt) }}
        </div>
        <hr />
        <div v-if="isCondition == true">
          <h6 class="text-left font-weight-normal">
            เงื่อนไขการใช้คูปอง
          </h6>
          <hr class="" />
          <p class="text-left text-description" style="font-size:12px">
            <span v-if = "dueDurationDescription() != null">
              • {{ dueDurationDescription() }} <br/>
            </span>
            • แสดงคูปองต่อพนักงาน <br />
            • สิทธิพิเศษนี้ไม่สามารถเปลี่ยน แลก ทอน เป็นเงินสดได้ <br />
            • สงวนสิทธิ์ในการเปลี่ยนแปลง แก้ไข โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
            <br />
            <span v-if="limitation !== ''">
              • จำกัดสิทธิ์ {{ limitation }} คูปอง ต่อ 1 สิทธิ์
            </span>
          </p>
        </div>
        <div class="text-left text-description" v-if="isCondition == false">
          <span v-if = "dueDurationDescription() != null" style="font-size:12px" >
              • {{ dueDurationDescription() }} <br/>
            </span>
          <p v-if="limitation !== ''" class="text-left text-description" style="font-size:12px">
            • จำกัดสิทธิ์ {{ limitation }} คูปอง ต่อ 1 สิทธิ์
          </p> 
        </div>
        <CRow>
          <CCol class="pt-3">
            <CButton @click="popupModal = true" block color="success" size="lg" :disabled="this.loadingButton">
              <em class="fa fa-ticket"> </em> แตะเพื่อรับสิทธิ์
            </CButton>
          </CCol>
        </CRow>
      </CCard>
    </CRow>
    <CRow>
      <CModal
        :show.sync="popupModal"
        :footer="footer"
        centered
        title="ยืนยันการแลกคะแนน">
        <h5 class="text-center">แลก {{ data.redeemFor || 0}} คะแนน เพื่อรับคูปอง</h5>
        <div class="text-center text-description" style="font-size:14px">
          การแลกคะแนนจะไม่สามารถยกเลิกหรือขอคืนได้
        </div>
        <div class="text-center text-description" style="font-size:14px">
          {{ description2()}}
        </div>
        <template #footer-wrapper>
          <CRow class="justify-content-center">
            <CCol col="5" class="ml-1">
              <CButton
                block
                color="success"
                v-on:click="redeemClick"
                :disabled="loadingButton === true">
                รับคูปอง
              </CButton>
            </CCol>
            <CCol col="5" class="mr-1">
              <CButton block color="danger" @click="popupModal = false">
                ยกเลิก
              </CButton>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
  </CContainer>
</template>

<script>
import moment from 'moment'
import crm from '@/services/crm'
import pos from '@/services/poscrm'
import liff from '@line/liff'
import util from '@/util/asymmetric'

export default {
  data() {
    return {
      data: {},
      pointtoredeem: '',
      popupModal: false,
      footer: '',
      member: {},
      memberObjectId: "",
      memberName: '',
      yourpoint: 0,
      endAt: '',
      value: '',
      redeemAlert: '',
      isCondition: '',
      limitation: '',
      profile: JSON.parse(localStorage.getItem('profile')),
      loadingButton: false,
      local: '',
      processing: false,
      dueDuration: null,
      dueDurationLabel: null,
    }
  },
  async created() {
    await this.getCoupon()
    this.getMemberByUserId()
  },
  computed: {
    userId() {
      if (process.env.NODE_ENV === "development") {
        return process.env.VUE_APP_LINE_USER_ID
      } else {
        if (this.profile) {
          return this.profile.userId || liff.getContext().userId
        } else {
          alert('มีข้อมูลผิดพลาด กรุณาติดต่อเจ้าหน้าที่')
          liff.closeWindow()
        }
      } 
    },
    uid() {
      return this.$route.query.uid
    },
    objectId() {
      return this.$route.query.objectId
    },
    noImgUrl() {
      return process.env.VUE_APP_NOIMAGE
    },
  },
  methods: {
    ...util,
    dateRange(startAt, endAt) {
      if (startAt === '' && endAt === '') {
        return 'ไม่จำกัดระยะเวลา'
      } else if (startAt == null && endAt == null) {
        return 'ไม่จำกัดระยะเวลา'
      } else {
        let start = moment(startAt).format('DD/MM/YYYY')
        let end = moment(endAt).format('DD/MM/YYYY')
        return start + ' ถึง ' + end
      }
    },
    description2() {
      if (this.redeemtionExpire() != null) {
        return "*คูปองนี้สามารถเก็บไว้ใช้ภายหลังได้ จนกว่าจะหมดอายุใน " + this.redeemtionExpire()
      } else {
        return "*คูปองนี้สามารถเก็บไว้ใช้ภายหลังได้ จนกว่าจะหมดอายุ"
      }
    },
    redeemtionExpire() {
      if (this.dueDuration != null) {
        return moment().add(this.dueDuration, 'm').format('DD MMM YYYY / HH:mm')
      } 
      return null
    },
    dueDurationDescription() {
      if (this.dueDuration != null && this.dueDurationLabel != null) {
        return "คูปองมีอายุ " + this.dueDurationLabel + " หลังจากกดรับสิทธิ์"
      } 
      return null
    },
    async getMemberByUserId() {
      const uid = this.uid
      const userId = this.userId
      try {
        const res = await pos.get('/api/v1.0/member/' + uid + '/getbyuserId/' + userId)
        this.member = res.data.data.documents
        if (this.member != null) {
          this.memberObjectId = this.member.objectId
          this.yourpoint = this.member.point
          this.memberName = this.member.name
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getCoupon() {
      let uid = this.uid
      let objectId = this.objectId
      try {
        const res = await crm.get('/api/v1.0/' + uid + '/coupon/get/' + objectId)
        this.data = res.data.data.documents
        this.pointtoredeem = this.data.redeemFor
        this.endAt = this.data.endAt
        this.value = this.data.value
        this.isCondition = this.data.isCondition
        this.limitation = this.data.limitation
        this.dueDuration = this.data.dueDuration || null
        this.dueDurationLabel = this.data.dueDurationLabel || null
        document.title = 'รับคูปอง - ' + this.data.title
      } catch (error) {
        console.log(error)
      }
    },
    redeemClick() {
      this.loadingButton = true
      const uid = this.uid
      const member = {
        objectId: this.memberObjectId,
        name: this.memberName,
      }
      const memberObjectId = this.memberObjectId
      const couponObjectId = this.objectId
      const userId = this.userId
      let token = util.asymmetric()

      let redeemdata = {
        redeemFor: this.pointtoredeem,
        userId: userId,
        couponObjectId: this.objectId,
        expireAt: this.endAt,
        value: this.value,
        yourpoint: this.yourpoint,
        member: member,
        headers: {
          'Content-Type': 'application/json',
          token: token,
          app: 'crm-app',
        },
      }

      if (memberObjectId === '' || memberObjectId === undefined) {
        this.redeemAlert = 'คุณไม่สามารถแลกคูปองได้ เนื่องจากยังไม่ได้เป็นสมาชิกร้านค้า สามารถสมัครสมาชิกได้ที่เมนูสมัครสมาชิก'
        this.popupModal = false
        this.loadingButton = false
        setTimeout(() => {
          this.redeemAlert = ''    
        }, 2000)
      } else {
        crm.post('/api/v1.0/' + uid + '/coupon/' + memberObjectId + '/redeem', redeemdata)
        .then((res) => {
          const response = res.data
          const error = response.error || {}
          
          if (error.code === 0) {
            this.popupModal = false
            const data = response.data
            let redemptionObjectId = data.objectId
            // console.log('process.env.VUE_APP_WEB_URL', process.env.VUE_APP_WEB_URL)
            // ไปหน้าที่แสดงคูปองเด้อจ้า
            window.location.replace(
              'redeem/' +
              uid +
              '/' +
              couponObjectId +
              '?userId=' +
              userId +
              '&redemptionObjectId=' +
              redemptionObjectId)
          } else {
            this.popupModal = false
            this.loadingButton = false
            this.redeemAlert = error.message || "Error" 
            setTimeout(() => {
              this.redeemAlert = ''    
            }, 2000)
          }
        }).catch((error) => {
          this.popupModal = false
          this.loadingButton = false
          console.log(error)
        })
      }
    },
  },
}
</script>

<style>
.square-box {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 5%;
}
.modal-confirm {
  width: 80%;
  margin: auto;
  border-radius: 10%;
}
</style>
